import React from "react"
import Layout from "../../components/js/layout"
import SEO from "../../components/js/seo"

import "../../components/css/page/book.css"

class BookPage extends React.Component {

  state = { 
    screenWidth: 1120
  }

  openBookPage () {
    // window.open('https://marpple.shop/kr/yalco/', '_blank')
    window.open('https://marpple.shop/kr/yalco', '_blank')
  }

  render () {
    return (
      <Layout page="book">
        <SEO title="혼공얄코"/>
        <section className="yalcobook">
          <h1 className="yalcobook__title">
            혼자 공부하는 얄팍한 코딩지식
            <span>#혼공얄코</span>
          </h1>
          <p className="yalcobook__brief">
            <span role='img' aria-label="book">📘</span> 비전공자도 1:1 과외하듯 배우는 IT 지식 입문서
          </p>

          <iframe 
            width={this.state.screenWidth}
            height={this.state.screenWidth * 9 / 16}
            className="yalcobook__movie" 
            src="https://www.youtube.com/embed/AT9lKhq2ULE" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
          ></iframe>

          <ul className="yalcobook__store-list">
            <li>
              <a href="https://product.kyobobook.co.kr/detail/S000213435960" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 책 구매하러 가기</a>
            </li>
            {/* <li>
              <a href="https://hanb.link/1a78" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 예스24</a>
            </li>
            <li>
              <a href="https://hanb.link/1a76" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 알라딘</a>
            </li>
            <li>
              <a href="https://hanb.link/1a77" target="_blank" rel="noreferrer">
                <span role='img' aria-label="book">🛒</span> 한빛미디어</a>
            </li> */}
          </ul>

        </section>

          <ul className="yalcobook__details">
            <li>
              <img src="/images/books/hongongyalco/01.jpg" alt="" />
            </li>
          </ul>


      </Layout>
    )
  }

  componentDidMount () {

    if (window.screen.width < 1120) {
      this.setState(() => {
        return {
          screenWidth: window.screen.width
        }
      })
    }
  }

}


// const ShopsPage = () => {
// }

export default BookPage